import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  getSelectOption,
  getSelectOptions,
  getDate,
  validateForm,
  showAddress,
  showDate,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { fetchBalances } from 'actions/inventory'
import { handleDelete, setBalances } from 'actions/ticket'
import { Flex } from 'reflexbox'
import { Definition, Button, Table, Center, Text } from 'components/core'
import {
  TextInput,
  TextArea,
  Select,
  Address,
  DateInput,
  PhoneInput,
} from 'components/form'
import { MdEdit, MdDelete } from 'react-icons/md'
import { filterLocations } from 'utilities/permission'

export const initialState = (value = {}, message) => {
  const isConfirmed = value.extra?.isConfirmed || false
  let status = value.status || 'PENDING'
  if (status === 'PENDING' && isConfirmed) status = 'ACTION_PENDING'
  return {
    isReady: value.isReady || false,
    id: value.id || '',
    locked: value.locked || false,
    status,
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    warehouses: value.warehouses || [],
    fromLocationName: value.fromLocationName || '',
    toLocationName: value.toLocationName || '',
    shippings: value.shippings || [],
    sales: getSelectOptions(value.sales),
    salesName: value.salesName || [],
    products: value.products || [],
    ...initializeState({
      estimateDate: getDate(value.extra?.estimateDate),
      transDate: getDate(value.transDate),
      arrivePeriod: getArrivePeriod(value.extra?.arrivePeriod, message),
      ticketNo: value.ticketNo || '',
      fromLocationId: getSelectOption(value.warehouses, value.fromLocationId),
      // recipientName: value.recipientName || '',
      recipientContact: value.extra?.recipientContact || '',
      recipientAddress: value.extra?.recipientAddress || '',
      recipientEmail: value.extra?.recipientEmail || '',
      recipientPhone: value.extra?.recipientPhone || '',
      recipientCellphone: value.extra?.recipientCellphone || '',
      shippingId: getSelectOption(value.shippings, value.extra?.shippingId),
      shippingName: value.extra?.shippingName || '',
      toLocationId: getSelectOption(value.warehouses, value.toLocationId),
      memo: value.extra?.memo || '',
      trackingNo: value.extra?.trackingNo || '',
    }),
  }
}

function getArrivePeriod(value, message) {
  if (!value) value = 'AM'
  return { value, label: message({ id: `transferIn.arrivePeriod.${value}` }) }
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  fromLocationId: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
  recipientContact: [{ type: 'required', message: 'error.required' }],
  recipientAddress: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
}

const defs = (state, message) => ({
  id: state.id,
  estimateDate: state.estimateDate,
  transDate: state.transDate,
  arrivePeriod: state.arrivePeriod.label,
  ticketNo: state.ticketNo,
  fromLocation: state.fromLocationName,
  // recipient: state.recipientName,
  recipientContact: state.recipientContact,
  recipientAddress: showAddress(state.recipientAddress, message),
  recipientPhone: state.recipientPhone,
  recipientCellphone: state.recipientCellphone,
  shipping: state.shippingName,
  trackingNo: state.trackingNo,
  toLocation: state.toLocationName,
  // sales: state.salesName,
  memo: state.memo,
})

const columns = ({ action, message }) => [
  {
    id: 'productVariantName',
    label: 'transferIn.field.spu',
  },
  {
    id: 'batchNo',
    label: 'transferIn.field.batchNo',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra.batchItems?.map(({ batchNo }, index) =>
          renderBatchItem(batchNo, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems
        ?.map(({ batchNo }) => batchNo || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'expireAt',
    label: 'transferIn.field.expireAt',
    noWrap: true,
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra.batchItems?.map(({ expireAt }, index) =>
          renderBatchItem(showDate(expireAt), index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems
        ?.map(({ expireAt }) => showDate(expireAt) || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'batchQuantity',
    label: 'transferIn.field.batchQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column" justifyContent="flex-end">
        {row.extra.batchItems?.map(({ quantity }, index) =>
          renderBatchItem(quantity, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems?.map(({ quantity }) => quantity).join('<br/>'),
  },
  {
    id: 'quantity',
    label: 'transferIn.field.quantity',
    align: 'right',
  },
]

function renderBatchItem(value, index) {
  return (
    <Text
      key={index}
      mt={index === 0 ? 0 : 2}
      color={value ? 'dark.1' : 'light.3'}
    >
      {value ? value : '\u2014'}
    </Text>
  )
}

export const labels = ({ state, message, action }) => {
  const fields = Object.entries(defs(state, message))
  const content = fields.reduce((result, [id, value]) => {
    const label = `transferIn.field.${id}`

    switch (id) {
      case 'sales':
        result[id] = (
          <Definition
            label={label}
            value={value.map((item) => item).join(', ')}
          />
        )
        break
      default:
        result[id] = <Definition label={label} value={value} />
    }

    return result
  }, {})

  content.product = (
    <Table
      showSeq
      columns={columns({ action, message })}
      rows={state.ticketItems}
    />
  )
  return content
}

export const fields = ({
  app,
  session,
  state,
  setState,
  message,
  profile,
  action,
}) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    id: (
      <Definition show={!!state.id} label="field.ticketId" value={state.id} />
    ),
    estimateDate: (
      <DateInput
        id="estimateDate"
        label="transferIn.field.estimateDate"
        value={state.estimateDate}
        role="lockTransferInTicket"
        onChange={onTextChange('estimateDate')}
        errMsg={state.__error__.estimateDate}
      />
    ),
    transDate: (
      <DateInput
        id="transDate"
        label="transferIn.field.transDate"
        value={state.transDate}
        role="lockTransferInTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    arrivePeriod: (
      <Select
        label="transferIn.field.arrivePeriod"
        isSearchable={false}
        isClearable={false}
        options={[
          { value: 'AM', label: message({ id: `transferIn.arrivePeriod.AM` }) },
          { value: 'PM', label: message({ id: `transferIn.arrivePeriod.PM` }) },
        ]}
        value={state.arrivePeriod}
        onChange={onSelectChange('arrivePeriod')}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="transferIn.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    fromLocation: (
      <Select
        label="transferIn.field.fromLocation"
        isClearable={false}
        options={state.warehouses}
        value={state.fromLocationId}
        onChange={onSelectChange('fromLocationId', ({ value }) =>
          onFromLocationChange(state, app, session, message, value),
        )}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    recipientContact: (
      <TextInput
        id="recipientContact"
        label="transferIn.field.recipientContact"
        value={state.recipientContact}
        onChange={onTextChange('recipientContact')}
        errMsg={state.__error__.recipientContact}
      />
    ),
    recipientAddress: (
      <Address
        id="recipientAddress"
        label="transferIn.field.recipientAddress"
        placeholder="transferIn.field.recipientAddress"
        value={state.recipientAddress}
        onChange={onTextChange('recipientAddress')}
        errMsg={state.__error__.recipientAddress}
      />
    ),
    recipientEmail: (
      <TextInput
        id="recipientEmail"
        label="transferIn.field.recipientEmail"
        value={state.recipientEmail}
        onChange={onTextChange('recipientEmail')}
        errMsg={state.__error__.recipientEmail}
      />
    ),
    recipientPhone: (
      <PhoneInput
        id="recipientPhone"
        label="transferIn.field.recipientPhone"
        value={state.recipientPhone}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientCellphone: (
      <PhoneInput
        id="recipientCellphone"
        label="transferIn.field.recipientCellphone"
        type="cellphone"
        value={state.recipientCellphone}
        onChange={onTextChange('recipientCellphone')}
        errMsg={state.__error__.recipientCellphone}
      />
    ),
    shipping: (
      <Select
        label="transferIn.field.shipping"
        isClearable={false}
        options={state.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    toLocation: (
      <Select
        label="transferIn.field.toLocation"
        isClearable={false}
        options={state.warehouses}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
        errMsg={state.__error__.toLocationId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="transferIn.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    trackingNo: (
      <TextInput
        id="trackingNo"
        label="transferIn.field.trackingNo"
        value={state.trackingNo}
        onChange={onTextChange('trackingNo')}
        errMsg={state.__error__.trackingNo}
      />
    ),
    product: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'transferIn.field.spu',
          },
          {
            id: 'batchNo',
            label: 'transferIn.field.batchNo',
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra.batchItems?.map(({ batchNo }, index) =>
                  renderBatchItem(batchNo, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'expireAt',
            label: 'transferIn.field.expireAt',
            noWrap: true,
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra.batchItems?.map(({ expireAt }, index) =>
                  renderBatchItem(showDate(expireAt), index),
                )}
              </Flex>
            ),
          },
          {
            id: 'batchQuantity',
            label: 'transferIn.field.batchQuantity',
            align: 'right',
            render: ({ row }) => (
              <Flex flexDirection="column" justifyContent="flex-end">
                {row.extra.batchItems?.map(({ quantity }, index) =>
                  renderBatchItem(quantity, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'balance',
            label: 'transferIn.field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'transferIn.field.quantity',
            align: 'right',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <Center justifyContent="flex-end">
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleOpen({ ...row, index })}
                />
                <Button
                  icon={<MdDelete />}
                  variant="icon"
                  onClick={() => {
                    const ticketItems = cloneDeep(state.ticketItems)
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                  }}
                />
              </Center>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  history,
  message,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editTransferIn(state, app, session)
      : await addTransferIn(state, app, session)
    if (!ok) return
    if (!id) id = data.addTransferInTicket

    history.push(`/transfer-in/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('transfer_in', { session, app, id, hash })
    if (!ok) return false

    history.push('/move')
    return true
  },
  addItem: (value) => {
    const ticketItems = [...state.ticketItems]
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleConfirm: (status) => async () => {
    const { hash } = state
    const [ok] = await confirmTransferIn({ session, app, id, hash, status })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  hanldeComplete: async () => {
    const [ok] = await completeTransferIn(state, app, session)
    if (!ok) return

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state, message }) {
  const title = { id: 'transferIn.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const field = Object.entries(defs(state, message)).map(([id, value]) => {
    const label = `transferIn.field.${id}`
    switch (id) {
      case 'sales':
        return { label, value: value.map(({ label }) => label).join(', ') }
      default:
        return { label, value }
    }
  })
  const list = { columns: columns({ message }), rows: state.ticketItems }
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: list },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const staffInput = { type: ['SALES'] }
  const locationInput = { type: ['WAREHOUSE', 'COMPANY'] }
  const variables = { id, locationInput, staffInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
        barcode
      }
      transferInTicket(id: $id) {
        ticketNo
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      transferInTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        quantity
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.transferInTicket || {}
  const ticketItems = data.transferInTicketItems
  const oldTicketItems = cloneDeep(ticketItems)
  const extra = ticket.extra || {}
  const warehouses = []
  const shippings = []

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'WAREHOUSE':
        warehouses.push(result)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  if (profile === 'edit' && ticketItems.length > 0) {
    const balances = await getBalances(
      app,
      session,
      ticketItems,
      oldTicketItems,
      ticket.fromLocationId,
    )
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: extra?.locked,
    // estimateDate: extra.estimateDate,
    transDate: ticket.transDate,
    // arrivePeriod: extra.arrivePeriod,
    ticketNo: ticket.ticketNo,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientEmail: extra.recipientEmail,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // shippingId: extra.shippingId,
    // shippingName: extra.shippingName,
    // trackingNo: extra.trackingNo,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    // salesName: extra.salesName,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    warehouses,
    shippings,
    sales: staffs,
    products: productVariants,
  }
}

async function onFromLocationChange(state, app, session, fromLocationId) {
  const { ticketItems, oldTicketItems } = state
  const balances = await getBalances(
    app,
    session,
    ticketItems,
    oldTicketItems,
    fromLocationId.value,
  )
  setBalances(ticketItems, balances)

  return { ticketItems }
}

async function getBalances(
  app,
  session,
  ticketItems,
  oldTicketItems,
  fromLocationId,
) {
  if (ticketItems.length === 0) return []

  const productVariantId = ticketItems.map((item) => item.productVariantId)
  return fetchBalances({
    app,
    session,
    locationType: 'WAREHOUSE',
    locationId: fromLocationId,
    productVariantId,
    oldTicketItems,
  })
}

async function addTransferIn(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addTransferInTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editTransferIn(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editTransferInTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function completeTransferIn(state, app, session) {
  const variables = {
    id: state.id,
    input: { status: 'ACTIVE', hash: state.hash },
  }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editTransferInTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function confirmTransferIn({ session, app, id, hash, status }) {
  const variables = { id, input: { hash, status } }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmTransferInTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => {
    const { extra } = item
    return {
      id: item.id,
      productVariantId: item.productVariantId,
      quantity: parseInt(item.quantity),
      extra,
    }
  })
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    id,
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    fromLocationId: state.fromLocationId.value,
    toLocationId: state.toLocationId.value,
    extra: {
      estimateDate: state.estimateDate,
      transDate: state.transDate,
      arrivePeriod: state.arrivePeriod.value,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientEmail: state.recipientEmail,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      shippingId: state.shippingId?.value,
      shippingName: state.shippingId?.label,
      trackingNo: state.trackingNo,
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

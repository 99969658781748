import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { getLimit, getPagination } from 'utilities/pagination'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { getMoveTypes } from './movePage'
import { Flex } from 'reflexbox'

export const filters = ({ staff, data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'arriveDate',
    label: 'move.field.arriveDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketType',
    label: 'move.field.ticketType',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          {
            value: 'MOVE',
            label: props.message({ id: 'move.ticketType.MOVE' }),
          },
          {
            value: 'TRANSFER_IN',
            label: props.message({ id: 'move.ticketType.TRANSFER_IN' }),
          },
          {
            value: 'TRANSFER_OUT',
            label: props.message({ id: 'move.ticketType.TRANSFER_OUT' }),
          },
        ],
      }),
  },
  {
    id: 'type',
    label: 'field.type',
    input: (props) =>
      selectFilter({
        ...props,
        options: getMoveTypes(staff, props.message),
      }),
  },
  {
    id: 'fromLocation',
    label: 'move.field.fromLocation',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getLocationOptions(data.locations, props.message),
      }),
  },
  {
    id: 'toLocation',
    label: 'move.field.toLocation',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getLocationOptions(data.locations, props.message),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getLocationOptions(locations, message) {
  if (!locations) return []
  const warehouses = []
  const depts = []
  const orgs = []
  const dealers = []
  locations.forEach(({ type, id, name }) => {
    const option = { label: name, value: id }
    switch (type) {
      case 'DEPT':
        depts.push(option)
        break
      case 'DEALER_ORG':
        orgs.push(option)
        break
      case 'DEALER':
        dealers.push(option)
        break
      default:
        warehouses.push(option)
    }
  })
  return [
    { label: message({ id: 'location.type.WAREHOUSE' }), options: warehouses },
    { label: message({ id: 'location.type.DEPT' }), options: depts },
    { label: message({ id: 'location.type.DEALER_ORG' }), options: orgs },
    { label: message({ id: 'location.type.DEALER' }), options: dealers },
  ]
}

function getStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
    {
      value: 'CALLBACK_PENDING',
      label: message({ id: 'status.CALLBACK_PENDING' }),
    },
    {
      value: 'ACTION_PENDING',
      label: message({ id: 'move.status.ACTION_PENDING' }),
    },
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'move.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => {
      const type = row.ticketType.toLowerCase().replace('_', '-')
      return (
        <Flex>
          <Link variant="primaryLink" href={`/${type}/${row.id}/view`}>
            {row.id}
          </Link>
          {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
        </Flex>
      )
    },
  },
  {
    id: 'transDate',
    label: 'move.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'arriveDate',
    label: 'move.field.arriveDate',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (row.ticketType === 'TRANSFER_IN') {
        return showDate(row.extra?.arriveDate)
      }
      return showDate(row.extra?.arriveDate)
    },
  },
  {
    id: 'type',
    label: 'move.field.type',
    format: ['excel'],
    render: ({ row }) => {
      if (row.ticketType !== 'MOVE') {
        return message({ id: 'move.ticketType.crossSystem' })
      }
      return message({ id: `move.type.${row.extra?.type}` })
    },
  },
  {
    id: 'fromLocationName',
    label: 'move.field.fromLocation',
    format: ['html', 'excel'],
  },
  {
    id: 'toLocationName',
    label: 'move.field.toLocation',
    format: ['html', 'excel'],
  },
  {
    id: 'address',
    label: 'move.field.address',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra?.address),
  },
  {
    id: 'phone',
    label: 'move.field.phone',
    format: ['excel'],
    render: ({ row }) => row.extra?.phone,
  },
  {
    id: 'contact',
    label: 'move.field.contact',
    format: ['excel'],
    render: ({ row }) => row.extra?.contact,
  },
  {
    id: 'memo',
    label: 'move.field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'product.field.code',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'move.field.quantity',
    format: ['excel'],
  },
  {
    id: 'batchNo',
    label: 'move.field.batchNo',
    format: ['excel'],
  },
  {
    id: 'expireAt',
    label: 'move.field.expireAt',
    format: ['excel'],
  },
  {
    id: 'lockQuantity',
    label: 'move.field.lockQuantity',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'move.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => {
      let status = row.status
      const isConfirmed = row.extra?.isConfirmed
      if (status === 'PENDING' && isConfirmed) status = 'ACTION_PENDING'

      return (
        <StatusChip
          label={`move.status.${status}`}
          color={getStatusColor(status)}
        />
      )
    },
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra?.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={getEditLink(row.ticketType, row.id)}
        />
        {renderDeleteButton({
          module: row.ticketType,
          session,
          locked: row.extra?.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete(row.ticketType, {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

function getEditLink(ticketType, id) {
  const type = ticketType.toLowerCase().replace('_', '-')
  return `/${type}/${id}/edit`
}

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setLocations,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'moveList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setLocations(resp.locations)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'move.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG', 'DEALER'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      moveTicketCount(input: $input)
      moveTickets(input: $input) {
        id
        ticketType
        fromLocationName
        toLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const { page, countPerPage } = pagination
  return {
    data: data.moveTickets,
    pagination: getPagination(page, countPerPage, data.moveTicketCount),
    locations,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      moveTickets(input: $input) {
        id
        ticketType
        ticketNo
        fromLocationName
        toLocationName
        productVariantName
        sku
        productCode
        quantity
        transDate
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.moveTickets.reduce((result, item) => {
    const extra = item.itemExtra || {}
    const batchItems = extra.batchItems || []
    if (!batchItems || batchItems.length === 0) {
      result.push(item)
      return result
    }

    batchItems.forEach((batchItem) => {
      const newItem = { ...item }
      newItem.batchNo = batchItem.batchNo
      newItem.expireAt = batchItem.expireAt
      newItem.lockQuantity = parseInt(batchItem.quantity || 0)

      result.push(newItem)
    })

    return result
  }, [])
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const arriveDate = filterValues.find(({ id }) => id === 'arriveDate')
  const ticketType = filterValues.find(({ id }) => id === 'ticketType')
  const type = filterValues.find(({ id }) => id === 'type')
  const fromLocation = filterValues.find(({ id }) => id === 'fromLocation')
  const toLocation = filterValues.find(({ id }) => id === 'toLocation')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const status = filterValues.find(({ id }) => id === 'status')
  const statusVal = status?.value.value
  const extra = {
    type: type?.value.value,
    locked: lockStatus?.value.value,
    arriveDate: arriveDate?.value,
  }

  if (statusVal === 'ACTION_PENDING') extra.isConfirmed = true
  if (statusVal === 'PENDING') extra.isConfirmed = false

  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    fromLocationId: fromLocation?.value.value,
    toLocationId: toLocation?.value.value,
    status: statusVal === 'ACTION_PENDING' ? 'PENDING' : statusVal,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    extra,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  const ticketTypeVal = ticketType?.value.value
  if (ticketTypeVal) input.ticketType = ticketTypeVal
  if (pagination) input.limit = getLimit(pagination)
  return input
}
